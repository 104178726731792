import {createTheme} from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#3360A3',
        },
        secondary: {
            main: '#1093A7',
        },
        error: {
            main: '#9C1A1C',
        },
        success: {
            main: '#91C94A',
            dark: '#6D9F31',
            contrastText: '#22310F'
        },
        background: {
            default: '#fff',
        },
        text: {
            primary: '#04090D',
            secondary: '#687076'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            // sm: 600,
            // md: 880,
            // lg: 1024,
            // xl: 1280,
            sm: 680,
            smd: 720,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    typography: {
        fontFamily: [
            "anext", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"
        ].join(','),
        fontWeightRegular: 400

    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                p: {
                    marginBlockStart: 0,
                },
                body: {
                    fontSize: 16,
                    lineHeight: "24px",
                    fontWeight: 400,
                    fontFeatureSettings: `"kern", "liga", "clig", "calt"`,
                    fontKerning: "normal"
                },
                h1: {
                    fontSize: 40,
                    lineHeight: "48px",
                    fontWeight: 600,
                    letterSpacing: -1
                },
                h2: {
                    fontSize: 27,
                    lineHeight: "32px",
                    letterSpacing: -.75,
                    marginTop: 0,
                    fontWeight: 600,
                },
                h3: {
                    marginTop: 0,
                    fontWeight: 600,
                },
                hr: {
                    border: "none",
                    background: "hsla(0,0%,0%,0.2)",
                    height: 1,
                    marginTop: 8,
                    marginBottom: 8
                },
                a: {
                    textDecoration: "underline",
                    color: "#3090CC"
                },
            },
        },
        MuiButton: {
            root: {
                paddingTop: 6,
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: 2
            },
        },
        MuiInputBase: {
            root: {

            },
            input: {

            }
        }
    },
});

export default theme;